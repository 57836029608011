import React from 'react';

import { Link } from 'gatsby';
import Chevron from '../../../images/SubPages/Chevron';

import './PageNotFound.scss';

export default function PageNotFound() {
  return (
    <div className='pnf-container'>
      <div className='pnf-wrapper'>
        <h1 className='pnf-title'>404</h1>
        <h2 className='pnf-subtitle'>oops... it seems you are lost</h2>
        <div className='pnf-button-block'>
          <Link to='/' className='pnf-button-link'>
            <Chevron svgClass='chevron first' />
            <Chevron svgClass='chevron second' />
            <Chevron svgClass='chevron third' />
            go back home
          </Link>
        </div>
      </div>
    </div>
  )
}
