import React from 'react';

import ErrorHeader from '../components/404/ErrorHeader';
import PageNotFound from '../components/404/PageNotFound';

export default function ErrorPage() {

  return (
    <section>
      <ErrorHeader />
      <PageNotFound />
    </section>
  )
}
